

const SmallAd = () => {
  return (
    <div className='p-3 ad_small_cover rounded-lg'>
        <div className="flex justify-center items-center">
        <p className="text-center text-2xl font-bold logo_text">LOGO</p>
        <span className="vertical-line">{"|"}</span>
        <span className="text-sm">COMPANYNAME.COM</span>
      </div>
      <div className="mt-3">
        <p className="text-xl font-semibold">
          Have you tried personalized Cart Abandonment messages on WhatsApp?
        </p>
      </div>
      <div className="w-3/4 mt-3">
        <p className="text-base">
          Did you know that over 70% of online shopping carts are abandoned?
          That's a lot of potential revenue lost!
        </p>
      </div>
      <div className="flex flex-col text-center">
        <div className="w-44 py-2 rounded-3xl bg-white mt-3">
          <p className="capitalize text-blue-950 text-xl font-bold">
            Get Free Demo
          </p>
        </div>
        <div className="w-44">
          <p className="mt-1 text-[6px]">
            *ipsum dolor sit amet, consectetur adipiscing elit.
          </p>
        </div>
      </div>
    </div>
  )
}

export default SmallAd