// // import LiveLabel from './LiveLabel';

// // const ScoreBoard = () => {
// //     const slides = Array.from({ length: 15 }, (_, index) => {
// //         return (
// //             <div key={index} className="w-full md:w-3/4 lg:w-2/3 xl:w-1/2 mx-auto px-4">
// //                 <div className="rounded-xl p-4 shadow-md border border-gray-200" style={{ width: '380px' }}>
// //                     <div className="flex justify-between">
// //                         <div className="flex items-center">
// //                             <p className="text-sm">3rd Match &nbsp; {/* Match name */}</p>
// //                             <span className="h-2 w-2 bg-gray-600 rounded-full font-semibold"></span> &nbsp;
// //                             <p className="text-sm scoreboard_title">ICC Cricket World Cup 2023</p>
// //                         </div>
// //                         <div className="inline-block px-2 py-1 rounded-xl ml-8 text-white bg-blue-500">
// //                             <p className="text-xs">Label</p>
// //                         </div>
// //                     </div>
// //                     {/* Body */}
// //                     <div className="my-3">
// //                         <div className="flex justify-between items-center">
// //                             <div className="flex items-center">
// //                                 <img src="/static/flags/afghanistan.png" className="h-4" alt="" />
// //                                 <p className="ml-2">AFG</p>
// //                             </div>
// //                             <p className="font-semibold mr-8">145-6 (33)</p>
// //                         </div>
// //                         <div className="flex items-center">
// //                             <img src="/static/flags/bangladesh.png" className="h-4" alt="" />
// //                             <p className="ml-2 text-gray-500">BAN</p>
// //                         </div>
// //                     </div>
// //                     <div className="border-b border-gray-300 mt-4"></div>
// //                     <div className="flex justify-between">
// //                         <div className="flex">
// //                             <LiveLabel />
// //                         </div>
// //                         <div className="flex text-gray-500 font-semibold">
// //                             <p className="mr-3">TABLE</p>
// //                             <p>SCHEDULE</p>
// //                         </div>
// //                     </div>
// //                 </div>
// //             </div>
// //         );
// //     });

// //     return (
// //         <div className="overflow-scroll">
// //             <div className="flex -mx-4">
// //                 {slides}
// //             </div>
// //         </div>
// //     );
// // };

// // export default ScoreBoard;


// import LiveLabel from './LiveLabel';

// const ScoreBoard = () => {
//     const slides = Array.from({ length: 15 }, (_, index) => {
//         return (
//             <div key={index} className="w-full md:w-3/4 lg:w-2/3 xl:w-1/2 mx-auto px-4">
//                 <div className="rounded-xl p-4 shadow-md border border-gray-200" style={{ width: '380px' }}>
//                     <div className="flex justify-between">
//                         <div className="flex items-center">
//                             <p className="text-sm">3rd Match &nbsp; {/* Match name */}</p>
//                             <span className="h-2 w-2 bg-gray-600 rounded-full font-semibold"></span> &nbsp;
//                             <p className="text-sm scoreboard_title">ICC Cricket World Cup 2023</p>
//                         </div>
//                         <div className="inline-block px-2 py-1 rounded-xl ml-8 text-white bg-blue-500">
//                             <p className="text-xs">Label</p>
//                         </div>
//                     </div>
//                     {/* Body */}
//                     <div className="my-3">
//                         <div className="flex justify-between items-center">
//                             <div className="flex items-center">
//                                 <img src="/static/flags/afghanistan.png" className="h-4" alt="" />
//                                 <p className="ml-2">AFG</p>
//                             </div>
//                             <p className="font-semibold mr-8">145-6 (33)</p>
//                         </div>
//                         <div className="flex items-center">
//                             <img src="/static/flags/bangladesh.png" className="h-4" alt="" />
//                             <p className="ml-2 text-gray-500">BAN</p>
//                         </div>
//                     </div>
//                     <div className="border-b border-gray-300 mt-4"></div>
//                     <div className="flex justify-between">
//                         <div className="flex">
//                             <LiveLabel />
//                         </div>
//                         <div className="flex text-gray-500 font-semibold">
//                             <p className="mr-3">TABLE</p>
//                             <p>SCHEDULE</p>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         );
//     });

//     return (
//         <div className="overflow-hidden"> {/* Changed overflow-scroll to overflow-hidden */}
//             <div className="flex -mx-4">
//                 {slides}
//             </div>
//         </div>
//     );
// };

// export default ScoreBoard;

import LiveLabel from './LiveLabel';

const ScoreBoard = () => {
    const slides = Array.from({ length: 15 }, (_, index) => {
        return (
            <div key={index} className="w-full md:w-3/4 lg:w-2/3 xl:w-1/2 mx-auto px-4">
                <div className="rounded-xl p-4 shadow-md border border-gray-200 bg-white" style={{ width: '380px' }}>
                    <div className="flex justify-between">
                        <div className="flex items-center">
                            <p className="text-sm">3rd Match &nbsp; {/* Match name */}</p>
                            <span className="h-2 w-2 bg-gray-600 rounded-full font-semibold"></span> &nbsp;
                            <p className="text-sm scoreboard_title">ICC Cricket World Cup 2023</p>
                        </div>
                        <div className="inline-block px-2 py-1 rounded-xl ml-8 text-white bg-blue-500">
                            <p className="text-xs">Label</p>
                        </div>
                    </div>
                    {/* Body */}
                    <div className="my-3">
                        <div className="flex justify-between items-center">
                            <div className="flex items-center">
                                <img src="/static/flags/afghanistan.png" className="h-4" alt="" />
                                <p className="ml-2">AFG</p>
                            </div>
                            <p className="font-semibold mr-8">145-6 (33)</p>
                        </div>
                        <div className="flex items-center">
                            <img src="/static/flags/bangladesh.png" className="h-4" alt="" />
                            <p className="ml-2 text-gray-500">BAN</p>
                        </div>
                    </div>
                    <div className="border-b border-gray-300 mt-4"></div>
                    <div className="flex justify-between">
                        <div className="flex">
                            <LiveLabel />
                        </div>
                        <div className="flex text-gray-500 font-semibold">
                            <p className="mr-3">TABLE</p>
                            <p>SCHEDULE</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    });

    return (
        <div className="overflow-scroll-hidden"> 
            <div className="flex -mx-4">
                {slides}
            </div>
        </div>
    );
};

export default ScoreBoard;
