import React from 'react';

const RankTable = () => {
    return (
        <table className="divide-y divide-gray-200">
            <thead className="bg-gray-50">
                <tr>
                    <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">Rank</th>
                    <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">Player</th>
                    <th scope="col" className="px-4 py-3 text-left text-xs font-medium text-gray-500 tracking-wider">Rating</th>
                </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
                {
                    [1,2,3,4,5].map((item:number) => {
                        return(
                            <tr key={item}>
                                <td className="px-4 py-4 whitespace-nowrap">{item}</td>
                                <td className="px-4 py-4 whitespace-nowrap">Player Name <p className="text-xs text-gray-500">(India)</p></td>
                                <td className="px-4 py-4 whitespace-nowrap">###</td>
                            </tr>
                        )
                    })
                }
                
            </tbody>
        </table>
    );
};

export default RankTable;

