export interface TableRow {
    team: string;
    matches: number;
    wins: number;
    losses: number;
    ties: number;
    noResults: number;
    points: number;
    netRunRate: string;
  }

export const tableData:TableRow[] = [
    {
      team: 'Team A',
      matches: 10,
      wins: 7,
      losses: 2,
      ties: 0,
      noResults: 1,
      points: 15,
      netRunRate: '+0.85'
    },
    {
      team: 'Team B',
      matches: 10,
      wins: 6,
      losses: 3,
      ties: 1,
      noResults: 0,
      points: 13,
      netRunRate: '+0.70'
    },
    {
      team: 'Team C',
      matches: 10,
      wins: 5,
      losses: 4,
      ties: 1,
      noResults: 0,
      points: 11,
      netRunRate: '+0.60'
    },
    {
      team: 'Team D',
      matches: 10,
      wins: 4,
      losses: 4,
      ties: 2,
      noResults: 0,
      points: 10,
      netRunRate: '+0.40'
    },
    {
      team: 'Team E',
      matches: 10,
      wins: 3,
      losses: 6,
      ties: 1,
      noResults: 0,
      points: 7,
      netRunRate: '-0.20'
    },
    {
      team: 'Team F',
      matches: 10,
      wins: 2,
      losses: 7,
      ties: 1,
      noResults: 0,
      points: 5,
      netRunRate: '-0.50'
    },
    {
      team: 'Team G',
      matches: 10,
      wins: 1,
      losses: 8,
      ties: 1,
      noResults: 0,
      points: 3,
      netRunRate: '-0.80'
    },
    {
      team: 'Team H',
      matches: 10,
      wins: 0,
      losses: 9,
      ties: 1,
      noResults: 0,
      points: 1,
      netRunRate: '-1.00'
    },
    {
      team: 'Team I',
      matches: 10,
      wins: 8,
      losses: 1,
      ties: 1,
      noResults: 0,
      points: 17,
      netRunRate: '+1.00'
    },
    {
      team: 'Team J',
      matches: 10,
      wins: 9,
      losses: 0,
      ties: 1,
      noResults: 0,
      points: 19,
      netRunRate: '+1.20'
    }
  ];
  