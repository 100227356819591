import { useRoutes } from 'react-router-dom'
import Teams from './Pages/Teams';
import Home from './Pages/Home';

const Routes = () => {
  return useRoutes([
    {
        path: '/',
        element  : <Home />
    },
    {
        path: '/teams',
        element  : <Teams />
    },

  ])
}

export default Routes;