import React from 'react';

const Ads = () => {
    return (
        <div className="mx-auto flex flex-col md:flex-row items-center justify-between px-3 py-3 bg-gray-100 ads_cover ads_container">
            <div className="flex items-center md:mr-4">
                <div className="mr-4 flex items-center justify-center py-7 px-4 border bg-white">
                    <p className="text-3xl font-semibold" style={{ color: '#360073' }}>LOGO</p>
                </div>
                <div className="w-full md:w-3/4 text-white">
                    <p className="text-2xl font-semibold">Have you tried personalized Cart Abandonment messages on WhatsApp?</p>
                    <p className="text-xs mt-2">Did you know that over 70% of online shopping carts are abandoned? That's a lot of potential revenue lost! Our AI-powered platform sends personalized messages to your customers.</p>
                </div>
            </div>

            {/* Right side button */}
            <button className="mt-4 md:mt-0 bg-blue-500 text-white w-full md:w-1/4 p-3 text-md font-semibold rounded-3xl border-2 border-blue-500 hover:bg-blue-600 hover:border-blue-600 focus:outline-none focus:border-blue-600 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50">Get Free Demo</button>
        </div>
    );
};

export default Ads;
