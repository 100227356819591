import React from "react";

const LiveLabel = () => {
  return (
    <>
      <span className="h-2 w-2 bg-red-600 rounded-full font-semibold mt-2 animate-pulse"></span>
      &nbsp;
      <span className="text-green-600 font-semibold">Live</span>
    </>
  );
};

export default LiveLabel;
