const UserChoice = () => {
    const data = ["IPL 2024","IPL Buzz","IPL Fixture", "IPL Fixture", "Top Trending Cricket Update's"]
  return (
    <div className="flex overflow-x-auto" style={{ scrollbarWidth: "none" }}>
        <div className="flex items-center rounded-xl p-2 py-4" style={{ backgroundColor: '#F7FAFF'  }}>
            <div className="ml-0.2 my-0.2 px-6 capitalize text-nowrap font-bold mr-4">
              User Choice
            </div>
            {
            data.map((item:string, index:number) => {
                return(
                    <div className="p-1 px-12 mx-2 my-0.2 bg-white rounded-2xl flex items-center" key={index}>
                        <p className='font-semibold text-nowrap'>
                          {item}
                        </p>
                        <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg" className="ml-2 mt-1">
                          <path d="M5.4159 6.00002L0.831299 1.40002L1.8851 0.346191L7.53892 6.00002L1.8851 11.6538L0.831299 10.6L5.4159 6.00002ZM11.7659 6.00002L7.18127 1.40002L8.2351 0.346191L13.8889 6.00002L8.2351 11.6538L7.18127 10.6L11.7659 6.00002Z" fill="#3A32D1"/>
                        </svg>
                    </div>
                )
            })
        }
        </div>
    </div>
  )
}

export default UserChoice
