
export interface ExclusiverlyVideoData {
    img: string,
    title: string
}

export const exclusiverlyVideoData:ExclusiverlyVideoData[] = [
    {
        img: '/static/exclusivelyVideos/1.png',
        title: 'IPL 2024: Lucknow Super Giants Preview ft. Harsha Bhogle'
    },
    {
        img: '/static/exclusivelyVideos/2.png',
        title: 'IPL 2024: Lucknow Super Giants Preview ft. Harsha Bhogle'
    },
    {
        img: '/static/exclusivelyVideos/3.png',
        title: 'IPL 2024: Lucknow Super Giants Preview ft. Harsha Bhogle'
    },
]