export interface MatchNews {
    title: string;
    dateTime: string;
}

export const matchNewsData: MatchNews[] = [
    {
        title: 'RCB change Bangalore to Bengaluru in team name',
        dateTime: '07-Oct-2023 • 10:30 AM'
    },
    {
        title: 'Mushfiqur Rahim ruled out of Sri Lanka Tests',
        dateTime: '08-Oct-2023 • 11:45 AM'
    },
    {
        title: 'Suryakumar Yadav fails to get NCA clearance; faces another fitness test on March',
        dateTime: '09-Oct-2023 • 02:15 PM'
    },
    {
        title: 'CSK to play first three home games at Mumbai’s DY Patil Stadium',
        dateTime: '10-Oct-2023 • 04:20 PM'
    },
    {
        title: 'Ravindra Jadeja to lead Gujarat Titans in IPL 2023',
        dateTime: '11-Oct-2023 • 06:00 PM'
    },
    {
        title: 'Kuldeep Yadav returns to Mumbai Indians for INR 4 crore',
        dateTime: '12-Oct-2023 • 08:30 PM'
    },
    {
        title: 'KKR signs up Fazalhaq Farooqi for INR 5 crore',
        dateTime: '13-Oct-2023 • 10:00 PM'
    },
    {
        title: 'Rajasthan Royals appoints Moeen Ali as vice-captain',
        dateTime: '14-Oct-2023 • 11:15 PM'
    },
    {
        title: 'Ishan Kishan named as captain of Mumbai Indians',
        dateTime: '15-Oct-2023 • 01:45 AM'
    },
    {
        title: 'KKR ropes in Dwaine Pretorius for INR 3 crore',
        dateTime: '16-Oct-2023 • 03:20 AM'
    }
];
