import { faceBookPostData } from "../Constants/faceBookPostData";
import { teamData } from "../Constants/teamData";
import { AiFillLike } from "react-icons/ai";
import { FaCommentAlt } from "react-icons/fa";
import { FaShare } from "react-icons/fa";

const Teams = () => {

  return (
    <div className="container mx-auto max-w-screen-xl px-4 pt-3 teams_page_warapper">
      <div className="grid grid-cols-1 md:grid-cols-12 gap-5">


        {/* LEFT SIDE */}
        <div className="col-span-1 md:col-span-8">
          <p className="capitalize font-semibold">CRICKET TEAMS</p>
          <div className="rounded-xl p-5 shadow bg-white mt-3">

            {/* TAB VIEW */}
            <div className="flex flex-wrap overflow-x-auto" style={{ scrollbarWidth: 'none' }}>
              <div className="flex rounded-xl border border-sky-500 p-0.5 tab_bar_wrappar">
                <div className="p-2 px-3 ml-0.2 my-0.2 tab_bar_selected_menu"> International </div>
                <div className="p-2 mx-3 my-0.2">Domestic</div>
                <div className="p-2 mx-3 my-0.2">League</div>
                <div className="p-2 mx-3 my-0.2">Women</div>
              </div>
            </div>

            
            {/* ALL TEAMS LIST */}
            <div className="rounded-xl border border-grey-800 mt-7 py-4 px-5">
                {
                    teamData.map((item, index:number) => {
                        return(
                            <div className="flex justify-start items-center my-7" key={`Tea, ${index}`}>
                                <img className="mr-3 w-10 h-10 rounded-full" src={item.flag} alt="Rounded avatar"  />
                                <p className="divide">
                                    {item.name}
                                </p>
                            </div>
                        )
                    })
                }
            </div>

          </div>
        </div>

        {/* RIGHT SIDE */}
        <div className="col-span-1 md:col-span-4">
          <div className="rounded-xl py-3 px-2 mt-2 bg-white overflow-y-scroll" style={{ height: '500px' }}>
              <p className="font-bold-500 uppercase font-semibold">
              Connect With Us
              </p>
              <div className="facebook_page p-3 mt-2 rounded-ss-xl rounded-tr-xl">
                <div className="grid grid-cols-12 gap-2">
                  <div className="col-span-3 p-1.5 rounded-xl" style={{ backgroundColor: '#3A32D1' }}>
                    <img src="/static/logo.png" alt="" className="w-full" />
                  </div>
                  <div className="col-span-8 p-1.5 rounded-xl">
                      <p className="uppercase text-white font-bold text-md" >Cricket Gully</p>
                      <div className="flex items-center">
                        <div className="rounded-xl bg-white p-1.5 flex">
                          <img src="/static/facebook_logo.png" alt="" className="h-4" />
                          <p style={{ color: '#4B4F56' }} className="text-xs pl-1" >Follow Page</p>
                        </div>
                        <p className="text-xs pl-1 text-white" >
                          7.5k followers
                        </p>
                      </div>
                      
                  </div>
                </div>
              </div>
              <div className="p-2" style={{ backgroundColor: '#EEF0F3' }}>
                {
                  faceBookPostData.map((item, index) => {
                    return(
                      <div className="news_list_warpper py-1.5 bg-white rounded-md px-2.5 mt-2" key={index}>
                        <div className="flex items-center">
                          <div className="w-14 h-14 rounded-full flex justify-center items-center" style={{ backgroundColor: '#3A32D1' }}>
                            <img src="/static/full_logo.png" alt="full logo" className="h-8" />
                          </div>
                          <div className="">
                            <p className="ml-1">
                              Cricket Gully
                            </p>
                            <p className="facebook_time_date">
                              {item.timdate}
                            </p>
                          </div>
                          <div className="ml-auto mb-auto">
                            <img src="/static/facebook_logo.png" alt="" className="h-4 mt-1 mr-1"  />
                          </div>
                        </div>
                        <div className="px-2 pt-2">
                          <p>
                            {item.caption}
                          </p>
                          <p style={{ color: '#415F9B' }}>
                            {item.hasTag}
                          </p>
                        </div>
                        <div className="border-b border-gray-300 mt-4"></div>
                        <div className="mt-2 mb-3 flex">
                          <div className="flex items-center text-gray-400">
                            <AiFillLike fontSize='medium' />
                            <span className="text-xs">11</span>
                          </div>
                          <div className="flex items-center text-gray-400 ml-8">
                            <FaCommentAlt fontSize='small' />
                            <span className="text-xs mb-0.5 ml-1">1</span>
                          </div>
                          <div className="flex items-center text-gray-400 ml-8">
                            <FaShare fontSize='medium' />
                            <span className="text-xs ml-1">Share</span>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
          </div>
          <div className="rounded-xl py-3 px-4 mt-5 bg-white">
            <p className="uppercase font-semibold">
              Follow Us
            </p>
            <p className="mt-3 ml-6">
              Instagram
            </p>
            <div className="border-b border-gray-300 my-4"></div>
            <p className="mt-3 ml-6">
              Facebook
            </p>
            <div className="border-b border-gray-300 my-4"></div>
            <p className="mt-3 ml-6">
              Youtube
            </p>
            <div className="border-b border-gray-300 my-4"></div>
            <p className="mt-3 ml-6">
              X <span className="text-gray-400">{"(Twitter)"}</span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Teams;
