export const teamData = [
    {
        flag: '/static/flags/afghanistan.png',
        name: 'Afghanistan'
    },
    {
        flag: '/static/flags/australia.png',
        name: 'Australia'
    },
    {
        flag: '/static/flags/bangladesh.png',
        name: 'Bangladesh'
    },
    {
        flag: '/static/flags/england.png',
        name: 'England'
    },
    {
        flag: '/static/flags/india.png',
        name: 'India'
    },
    {
        flag: '/static/flags/new_zealand.png',
        name: 'New Zealand'
    },
    {
        flag: '/static/flags/pakistan.png',
        name: 'Pakistan'
    },
    {
        flag: '/static/flags/south_africa.png',
        name: 'South Africa'
    },
    {
        flag: '/static/flags/sri_lanka.png',
        name: 'Sri Lanka'
    },
    {
        flag: '/static/flags/west_indies.png',
        name: 'West Indies'
    },
]