import React from "react";
import LiveLabel from "./LiveLabel";

const Tabview = () => {
  return (
    <div
      className="flex flex-wrap overflow-x-auto"
      style={{ scrollbarWidth: "none" }}
    >
      <div className="flex rounded-xl border border-sky-500 p-0.5 bg-white tab_bar_wrappar ">
        <div className="p-2 px-3 ml-0.2 my-0.2 tab_bar_selected_menu">
          International
        </div>
        <div className="p-2 mx-3 my-0.2">Domestic</div>
        <div className="p-2 mx-3 my-0.2">League</div>
        <div className="p-2 mx-3 my-0.2">Women</div>
        <div className="p-2 mx-3 my-0.2 flex">
          <p>CSK </p> &nbsp;
          <p className="text-gray-500"> v/s </p> &nbsp;
          <p>RCB</p> &nbsp;
          <LiveLabel />
        </div>
        <div className="p-2 mx-3 my-0.2 flex">
          <p> DC </p> &nbsp;
          <p className="text-gray-500"> v/s </p> &nbsp;
          <p> SRH </p>
        </div>
        <div className="p-2 mx-3 my-0.2 flex">
          <p> DC </p> &nbsp;
          <p className="text-gray-500"> v/s </p> &nbsp;
          <p> SRH </p>
        </div>
      </div>
    </div>
  );
};

export default Tabview;
