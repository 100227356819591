export const faceBookPostData = [
    {
        timdate: '07-Oct-2023  •  11:36AM',
        caption: "AUS Women vs IND Women Highlights: Who is Accountable for India's defeat, know Five Striking Reasons.",
        hasTag: "#iccwomenst20worldcup2023 #ausvsind #indvsaushighlights #indiawomenvsaustraliawomen #indiancricketteam #australiacricket #indwvsausw #indwvsauswsemifinal #indwvsauswhighlights #harmanpreetkaur #renukasingh #smritimandhana #richaghosh #deeptisharma #cricket #cricketnews #cricketupdates #cricketvideos #priyankajainofficial #cricketlineguru"
    },
    {
        timdate: '07-Oct-2023  •  11:36AM',
        caption: "AUS Women vs IND Women Highlights: Who is Accountable for India's defeat, know Five Striking Reasons.",
        hasTag: "#iccwomenst20worldcup2023 #ausvsind #indvsaushighlights #indiawomenvsaustraliawomen #indiancricketteam #australiacricket #indwvsausw #indwvsauswsemifinal #indwvsauswhighlights #harmanpreetkaur #renukasingh #smritimandhana #richaghosh #deeptisharma #cricket #cricketnews #cricketupdates #cricketvideos #priyankajainofficial #cricketlineguru"
    },
    {
        timdate: '07-Oct-2023  •  11:36AM',
        caption: "AUS Women vs IND Women Highlights: Who is Accountable for India's defeat, know Five Striking Reasons.",
        hasTag: "#iccwomenst20worldcup2023 #ausvsind #indvsaushighlights #indiawomenvsaustraliawomen #indiancricketteam #australiacricket #indwvsausw #indwvsauswsemifinal #indwvsauswhighlights #harmanpreetkaur #renukasingh #smritimandhana #richaghosh #deeptisharma #cricket #cricketnews #cricketupdates #cricketvideos #priyankajainofficial #cricketlineguru"
    },
    {
        timdate: '07-Oct-2023  •  11:36AM',
        caption: "AUS Women vs IND Women Highlights: Who is Accountable for India's defeat, know Five Striking Reasons.",
        hasTag: "#iccwomenst20worldcup2023 #ausvsind #indvsaushighlights #indiawomenvsaustraliawomen #indiancricketteam #australiacricket #indwvsausw #indwvsauswsemifinal #indwvsauswhighlights #harmanpreetkaur #renukasingh #smritimandhana #richaghosh #deeptisharma #cricket #cricketnews #cricketupdates #cricketvideos #priyankajainofficial #cricketlineguru"
    },
]