import React from 'react';

const SmallRankTabView = () => {
    return (
        <div className="text-xs font-medium text-center text-gray-500 border-b border-gray-300 dark:text-gray-400">
            <ul className="flex flex-wrap -mb-px">
                <li className="">
                    <a href="/" className="inline-block py-2 px-2 border-b-2 border-transparent rounded-t-lg">
                        Team
                    </a>
                </li>
                <li className="">
                    <a href="/" className="inline-block py-2 px-2 text-blue-600 border-b-2 border-blue-600 rounded-t-lg active dark:text-blue-500 dark:border-blue-500" aria-current="page">
                        Batting
                    </a>
                </li>
                <li className="">
                    <a href="/" className="inline-block py-2 px-2 border-b-2 border-transparent rounded-t-lg">
                        Bowling
                    </a>
                </li>
                <li className="">
                    <a href="/" className="inline-block py-2 px-2 border-b-2 border-transparent rounded-t-lg">
                        All-Rounder
                    </a>
                </li>
            </ul>
        </div>
    );
};

export default SmallRankTabView;
